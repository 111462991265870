import { useNavigate, useParams } from "react-router-dom";
import useChallenge, { Challenge } from "./hooks/use-challenge";
import { useEffect, useState } from "react";
import { extractYouTubeID } from "@/lib/extractYouTubeID";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronLeft } from "lucide-react";
import YoutubePlayer from "@/components/YoutubePlayer";
import useTopic, { Topic } from "../TopicDetail/hooks/use-topic";
import VideoAction from "@/components/VideoAction";
import CommentDrawer from "@/components/CommentDrawer";
import { Button } from "@/components/ui/button";
import ScoreDialog from "@/components/ScoreDialog/ScoreDialog";

const MeChallengeDetail = () => {
  const { topicId = "", challengeId = "" } = useParams<{
    topicId: string;
    challengeId: string;
  }>();
  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [topic, setTopic] = useState<Topic | null>(null);
  const { fetchChallenge } = useChallenge();
  const { fetchTopic } = useTopic();
  const [isOpenCommentDrawer, setIsOpenCommentDrawer] = useState(false);
  const [isOpenScoreDialog, setIsOpenScoreDialog] = useState(false);

  useEffect(() => {
    if (!challengeId) return;
    fetchChallenge({ challenged_id: challengeId }).then((challenge) => {
      setChallenge(challenge);
    });
    if (!topicId) return;
    fetchTopic(topicId).then((topic) => {
      setTopic(topic);
    });
  }, [challengeId, fetchChallenge, topicId, fetchTopic]);

  const navigate = useNavigate();

  const onCreatedComment = async () => {
    setChallenge(
      (prev) =>
        ({
          ...prev,
          comment_counts: prev && prev.comment_counts + 1,
        }) as Challenge
    );
  };

  const onCommentClicked = () => {
    setIsOpenCommentDrawer(true);
  };

  const showMyScore = () => {
    setIsOpenScoreDialog(true);
  };

  const videoId = extractYouTubeID(challenge?.video_url || "");

  return (
    <>
      <h1 className="text-center text-3xl font-bold">My Challenge</h1>
      <h2
        className="text-center text-2xl font-bold hover:cursor-pointer"
        onClick={() => navigate(`/topics/${topic?.topic_id}`)}
      >
        {topic && topic.name}
      </h2>
      <div className="mt-2">
        {videoId ? (
          <div className="h-[70vh] flex justify-center items-center">
            <VideoAction
              likes={challenge?.likes}
              commentCounts={challenge?.comment_counts}
              onLikeClicked={() => {}}
              onCommentClicked={onCommentClicked}
            >
              <YoutubePlayer videoId={videoId} />
              <CommentDrawer
                visible={isOpenCommentDrawer}
                onCLose={() => setIsOpenCommentDrawer(false)}
                topicId={topicId}
                challengeId={challengeId}
                onCreatedComment={onCreatedComment}
                commentCounts={challenge?.comment_counts || 0}
              />
            </VideoAction>
          </div>
        ) : (
          <Skeleton className="h-[70vh] aspect-[10/16] mx-auto" />
        )}
      </div>
      <div className="mt-2 flex items-center justify-between">
        <div className="flex-1 flex justify-start">
          <ChevronLeft
            className="h-8 w-8"
            onClick={() => navigate(`/my-challenges`)}
          />
        </div>
        <div className="flex-1"></div>
        <div className="flex-1 text-right">
          <Button onClick={showMyScore} disabled={!challenge?.score?.overall}>
            My Score
          </Button>
          {challenge && (
            <ScoreDialog
              isOpen={isOpenScoreDialog}
              onClose={() => setIsOpenScoreDialog(false)}
              score={{
                rhythmPrecision: challenge.score?.rhythm_precision,
                cleanMoves: challenge.score?.clean_moves,
                dress: challenge.score?.dress,
                confidence: challenge.score?.confidence,
                execution: challenge.score?.execution,
                overall: challenge.score?.overall,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MeChallengeDetail;
