import { useEffect, useState } from "react";
import useTopics, { Topic } from "@/pages/Topics/hooks/use-topics";
import { useAuth } from "@/provider/auth";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { useNavigate } from "react-router-dom";

const MyTopics = () => {
  const { userInfo } = useAuth();
  const { fetchTopics } = useTopics();
  const [topics, setTopics] = useState<Topic[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo?.customer_id) return;

    fetchTopics({ filter: { customer_id: userInfo.customer_id } })
      .then((data) => {
        setTopics(data.topics);
      })
      .catch(console.error);
  }, [userInfo, fetchTopics]);

  return (
    <div>
      <h1 className="text-2xl font-bold">My Topics</h1>
      <div className="mt-2 space-y-4">
        {topics.map((topic) => (
          <div key={topic.topic_id}>
            <Card className="border-2">
              <CardHeader className="text-2xl py-2 font-bold text-center">
                {topic.name}
              </CardHeader>
              <CardContent className="flex flex-row items-center border-t-2 p-2">
                <div className="flex-1 text-center">
                  <Button
                    onClick={() => {
                      navigate(`/topics/${topic.topic_id}`);
                    }}
                  >
                    Topic Detail
                  </Button>
                </div>
                <Separator
                  orientation="vertical"
                  className="mx-2 h-14 w-[2px]"
                />
                <div className="flex-1 text-center">
                  <Button
                    onClick={() => {
                      navigate(`/challenges/${topic.topic_id}`);
                    }}
                    disabled={topic.challenged_counts === 0}
                  >
                    Challenges
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyTopics;
