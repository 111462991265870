import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type CreateChallengeResponseType = {
  challenged_id: string;
};

export type PrivacyType =
  | "public"
  | "private"
  | "teacher-only"
  | "student-only";

export const useCreateChallenge = () => {
  const { fetcher, loading } =
    useFetch<ResponseType<CreateChallengeResponseType>>();

  const createChallenge = useCallback<
    (params: {
      topicId: string;
      customerId: string;
      videoUrl: string;
      privacy?: PrivacyType;
    }) => Promise<CreateChallengeResponseType>
  >(
    ({ topicId, customerId, videoUrl, privacy = "private" }) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: "challenge",
            options: {
              method: "POST",
              payload: {
                customer_id: customerId,
                topic_id: topicId,
                video_provider: "shorts",
                video_url: videoUrl,
                privacy,
              },
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching topics error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    createChallenge,
    loading,
  };
};

export default useCreateChallenge;
