import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Component as RadarComponent } from "./ScoreChart";

type ScoreDialogProps = {
  isOpen: boolean;
  onClose: () => void;
  score: {
    rhythmPrecision: number;
    cleanMoves: number;
    dress: number;
    confidence: number;
    execution: number;
    overall: number;
  };
};

const ScoreDialog = ({ isOpen, onClose, score }: ScoreDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent aria-describedby="" className="py-4">
        <DialogHeader>
          <DialogTitle>Challenge Score</DialogTitle>
        </DialogHeader>
        <RadarComponent
          rhythmPrecision={score.rhythmPrecision}
          cleanMoves={score.cleanMoves}
          dress={score.dress}
          confidence={score.confidence}
          execution={score.execution}
          overall={score.overall}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ScoreDialog;
