import tracker from "@/lib/tracker";

const CATEGORY = "topic_detail";

export const sendRecordButtonClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: `record_button_click`,
    label: `record_button_click`,
  });
};
export const sendNavigateToChallengesPageButtonClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "navigate_to_challenges_page",
    label: "navigate_to_challenges_page",
  });
};
