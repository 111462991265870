import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

type Settings = {
  featured: boolean;
};

export type Topic = {
  customer_id: string;
  name: string;
  topic_id: string;
  video_provider: string;
  video_url: string;
  difficulty: string;
  settings: Settings;
  challenged_counts: number;
  style: string;
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
};

export type TopicsResponseType = {
  topics: Topic[];
  total: number;
};

export type Payload = {
  search: string;
  filter: {
    featured: boolean;
    customer_id: string;
  };
  paging: {
    offset: number;
    limit: number;
  };
  order: {
    column: string;
    direction: string;
  };
};

export const useTopics = () => {
  const { fetcher, loading } = useFetch<ResponseType<TopicsResponseType>>();

  const fetchTopics = useCallback<
    (payload: Payload | unknown) => Promise<TopicsResponseType>
  >(
    (payload) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `get_topic_list`,
            options: {
              method: "POST",
              payload: payload || null,
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching topics error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchTopics,
    loading,
  };
};

export default useTopics;
