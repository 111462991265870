import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type Payload = {
  email: string;
  password: string;
};

export type SignUpResponseType = {
  auth_token: string;
  refresh_token: string;
};

export const useSingup = () => {
  const { fetcher, loading } = useFetch<ResponseType<SignUpResponseType>>();

  const fetchSignup = useCallback<
    (payload: Payload | unknown) => Promise<SignUpResponseType>
  >(
    (payload) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `signup`,
            options: {
              method: "POST",
              payload: payload || null,
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching signup error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchSignup,
    loading,
  };
};

export default useSingup;
