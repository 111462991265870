import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useCallback, useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { isEmail } from "@/lib/utils/common";
import { useAuth } from "@/provider/auth/";
import { useLocation, useNavigate } from "react-router-dom";
import {
  onEmailInputFocus,
  onPasswordInputFocus,
  sendClickSignupButtonEvent,
  sendLoginFailedEvent,
  sendLoginSuccessEvent,
  sendSubmitEvent,
} from "./utils/trackingEvent";

export function Login() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { toast, dismiss } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuth();

  const onSubmit = useCallback(async () => {
    sendSubmitEvent();
    if (!isEmail(email)) {
      return toast({
        description: "Email is invalid",
        variant: "destructive",
      });
    }

    if (!password) {
      return toast({
        description: "Password is required",
        variant: "destructive",
      });
    }

    try {
      await login(email, password);
      sendLoginSuccessEvent();
      toast({
        description: "Login successfully, redirecting...",
      });

      setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const redirectPath = params.get("redirect") || "/";
        navigate(decodeURIComponent(redirectPath));
        dismiss();
      }, 1500);
    } catch (error) {
      sendLoginFailedEvent();
      toast({
        description: "Login failed",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [email, login, location, navigate, password, toast, dismiss]);

  const onSignupClick = () => {
    sendClickSignupButtonEvent();
    const params = new URLSearchParams(location.search);
    const redirectPath = params.get("redirect");
    if (!redirectPath) return navigate("/signup");
    navigate(`/signup?redirect=${encodeURIComponent(redirectPath)}`);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [onSubmit]);

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="text-center">Hi, dancer 💃🕺</CardTitle>
        </CardHeader>

        <CardContent>
          <form>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="email">Email</Label>
                <Input
                  id="Email"
                  placeholder="example@google.com"
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={onEmailInputFocus}
                />
              </div>

              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={onPasswordInputFocus}
                />
              </div>
            </div>
          </form>
        </CardContent>

        <CardFooter className="flex flex-col justify-center">
          <Button className="w-full" onClick={onSubmit} disabled={loading}>
            {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Login
          </Button>
          <div className="my-4">Not a member yet?</div>
          <Button variant="secondary" onClick={onSignupClick}>
            Sign Up
          </Button>
        </CardFooter>
      </Card>
    </>
  );
}
