import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import tracker from "@/lib/tracker";
import Header from "./Header";
import Footer from "./Footer";
import { useScrollToTop } from "@/hooks/useScrollToTop";

export const Layout = () => {
  useScrollToTop();

  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return console.log("fire event:", location.pathname + location.search);
    }

    tracker.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <>
      <div>
        <Header />
        <div className="px-4 pb-8 min-h-[90vh] max-w-4xl mx-auto">
          <Outlet />
        </div>
        <Footer />
      </div>
    </>
  );
};
