import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type ScorePayload = {
  rhythm_precision: number;
  clean_moves: number;
  dress: number;
  confidence: number;
  execution: number;
  overall: number;
};

export const useScore = () => {
  const { fetcher, loading } = useFetch<ResponseType<any>>();

  const updateChallengeScore = useCallback<
    (challenged_id: string, payload: ScorePayload) => Promise<any>
  >(
    (challenged_id, payload) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `challenge/${challenged_id}/score`,
            options: {
              method: "POST",
              payload: payload || null,
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("updating challenge score error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    updateChallengeScore,
    loading,
  };
};

export default useScore;
