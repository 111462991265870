import tracker from "@/lib/tracker";

const CATEGORY = "scoring";

export const sendSubmitEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "scoring_form_submit",
    label: "scoring_form_submit",
  });
};

export const sendSuccessEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "scoring_success",
    label: "scoring_success",
  });
};

export const sendFailedEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "scoring_failed",
    label: "scoring_failed",
  });
};
