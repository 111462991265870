import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardFooter } from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import {
  sendSubmitEvent,
  sendSuccessEvent,
  sendFailedEvent,
} from "./utils/trackingEvent";
import useScore from "./hooks/useScore";
import { ChallengeScore } from "@/pages/ChallengeDetail/hooks/use-challenge";

const ScoringDialog = ({
  challengeId,
  score,
  onSuccess,
}: {
  challengeId: string;
  score?: ChallengeScore;
  onSuccess?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>Scoring</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]" aria-describedby={undefined}>
        <DialogTitle className="text-center text-2xl">Scoring</DialogTitle>
        <ScoringForm
          challengeId={challengeId}
          score={score}
          onSuccess={() => {
            setOpen(false);
            onSuccess?.();
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const ScoringForm = ({
  challengeId,
  score,
  onSuccess,
}: {
  challengeId: string;
  score: ChallengeScore | undefined;
  onSuccess?: () => void;
}) => {
  const [rhythmPrecision, setRhythmPrecision] = useState<number | null>(
    score?.rhythm_precision ?? null
  );
  const [cleanMoves, setCleanMoves] = useState<number | null>(
    score?.clean_moves ?? null
  );
  const [dress, setDress] = useState<number | null>(score?.dress ?? null);
  const [confidence, setConfidence] = useState<number | null>(
    score?.confidence ?? null
  );
  const [execution, setExecution] = useState<number | null>(
    score?.execution ?? null
  );
  const [overall, setOverall] = useState<number | null>(score?.overall ?? null);
  const [loading, setLoading] = useState<boolean>(false);
  const { toast } = useToast();
  const { updateChallengeScore, loading: scoreLoading } = useScore();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    sendSubmitEvent();

    if (
      rhythmPrecision === null ||
      cleanMoves === null ||
      dress === null ||
      confidence === null ||
      execution === null ||
      overall === null
    ) {
      return toast({
        description: "All fields are required",
        variant: "destructive",
      });
    }

    try {
      setLoading(true);
      await updateChallengeScore(challengeId, {
        rhythm_precision: rhythmPrecision,
        clean_moves: cleanMoves,
        dress: dress,
        confidence: confidence,
        execution: execution,
        overall: overall,
      });
      sendSuccessEvent();
      toast({
        description: "Scores submitted successfully",
      });
      onSuccess?.();
    } catch (error) {
      sendFailedEvent();
      toast({
        description: "Submission failed",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const submitButtonDisabled = loading || scoreLoading;

  return (
    <Card className="mt-2 border-0">
      <CardContent className="px-0">
        <form onSubmit={onSubmit}>
          <div className="grid w-full items-center gap-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="rhythm" className="text-right">
                Rhythm Precision
              </Label>
              <Input
                type="number"
                name="rhythm"
                id="rhythm"
                min="0"
                max="10"
                value={rhythmPrecision ?? ""}
                onChange={(e) => setRhythmPrecision(Number(e.target.value))}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="cleanMoves" className="text-right">
                Clean Moves
              </Label>
              <Input
                type="number"
                name="cleanMoves"
                id="cleanMoves"
                min="0"
                max="10"
                value={cleanMoves ?? ""}
                onChange={(e) => setCleanMoves(Number(e.target.value))}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="dress" className="text-right">
                Dress
              </Label>
              <Input
                type="number"
                name="dress"
                id="dress"
                min="0"
                max="10"
                onChange={(e) => setDress(Number(e.target.value))}
                value={dress ?? ""}
                className="col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="confidence" className="text-right">
                Confidence
              </Label>
              <Input
                type="number"
                name="confidence"
                id="confidence"
                min="0"
                max="10"
                onChange={(e) => setConfidence(Number(e.target.value))}
                className="col-span-3"
                value={confidence ?? ""}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="execution" className="text-right">
                Execution
              </Label>
              <Input
                type="number"
                name="execution"
                id="execution"
                min="0"
                max="10"
                onChange={(e) => setExecution(Number(e.target.value))}
                className="col-span-3"
                value={execution ?? ""}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="overall" className="text-right">
                Overall
              </Label>
              <Input
                type="number"
                name="overall"
                id="overall"
                min="0"
                max="10"
                onChange={(e) => setOverall(Number(e.target.value))}
                className="col-span-3"
                value={overall ?? ""}
              />
            </div>
          </div>
        </form>
      </CardContent>
      <CardFooter className="flex justify-center px-0">
        <Button
          type="submit"
          className="w-full"
          disabled={submitButtonDisabled}
          onClick={onSubmit}
        >
          {submitButtonDisabled && (
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
          )}
          Submit
        </Button>
      </CardFooter>
    </Card>
  );
};

export default ScoringDialog;
