import { useNavigate, useParams } from "react-router-dom";
import useChallenge, { Challenge } from "./hooks/use-challenge";
import { useEffect, useState } from "react";
import { extractYouTubeID } from "@/lib/extractYouTubeID";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronLeft } from "lucide-react";
import YoutubePlayer from "@/components/YoutubePlayer";
import VideoAction from "@/components/VideoAction";
import { useToast } from "@/components/ui/use-toast";
import CommentDrawer from "@/components/CommentDrawer";
import ScoringDialog from "@/components/ScoringDialog/ScoringDialog";
import { useAuth } from "@/provider/auth";

const ChallengeDetail = () => {
  const { topicId = "", challengeId = "" } = useParams<{
    topicId: string;
    challengeId: string;
  }>();
  const { toast } = useToast();
  const { fetchChallenge, likeChallenge } = useChallenge();

  const [challenge, setChallenge] = useState<Challenge | null>(null);
  const [isOpenCommentDrawer, setIsOpenCommentDrawer] = useState(false);
  const { userInfo } = useAuth();
  const [refetchChallenge, setRefetchChallenge] = useState(false);

  useEffect(() => {
    if (!challengeId) return;
    fetchChallenge({ challenged_id: challengeId }).then((challenge) => {
      setChallenge(challenge);
    });
  }, [challengeId, fetchChallenge, refetchChallenge]);

  const navigate = useNavigate();

  const onLikeClicked = () => {
    if (!challengeId) return;
    (async () => {
      try {
        const { likes } = await likeChallenge({ challenged_id: challengeId });
        toast({
          description: "Liked!",
          duration: 1500,
        });
        setChallenge((prev) => ({ ...prev, likes }) as Challenge);
      } catch (error) {
        console.error(error);
        toast({
          description: "Failed to like challenge",
          variant: "destructive",
        });
      }
    })();
  };

  const onCommentClicked = () => {
    setIsOpenCommentDrawer(true);
  };

  const onCreatedComment = async () => {
    setChallenge(
      (prev) =>
        ({
          ...prev,
          comment_counts: prev && prev.comment_counts + 1,
        }) as Challenge
    );
  };

  const videoId = extractYouTubeID(challenge?.video_url || "");

  const isShowScoringDialog =
    userInfo?.customer_id === challenge?.topic_customer_id;

  return (
    <>
      <div>
        {videoId ? (
          <div className="h-[75vh] flex justify-center items-center">
            <VideoAction
              likes={challenge?.likes}
              commentCounts={challenge?.comment_counts}
              onLikeClicked={onLikeClicked}
              onCommentClicked={onCommentClicked}
            >
              <YoutubePlayer videoId={videoId} />
              <CommentDrawer
                commentCounts={challenge?.comment_counts || 0}
                visible={isOpenCommentDrawer}
                onCLose={() => setIsOpenCommentDrawer(false)}
                topicId={topicId}
                challengeId={challengeId}
                onCreatedComment={onCreatedComment}
              />
            </VideoAction>
          </div>
        ) : (
          <Skeleton className="h-[75vh] aspect-[10/16] mx-auto" />
        )}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex-1 flex justify-start">
          <ChevronLeft
            className="h-8 w-8"
            onClick={() => navigate(`/Challenges/${topicId}`)}
          />
        </div>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full w-16 h-16 flex items-center justify-center border-2 border-white"
          onClick={() => navigate(`/upload/${topicId}`)}
        ></button>
        <div className="flex-1 text-right">
          {isShowScoringDialog && (
            <ScoringDialog
              challengeId={challengeId}
              score={challenge?.score}
              onSuccess={() => setRefetchChallenge((prev) => !prev)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ChallengeDetail;
