import "./App.css";
import { ThemeProvider } from "@/provider/theme";
import { Toaster } from "./components/ui/toaster";
import { AuthProvider } from "./provider/auth";
import Router from "./components/Router";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="dl-app-ui-theme">
      <AuthProvider>
        <Router />
        <Toaster />
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
