import { useNavigate, useParams } from "react-router-dom";
import useTopic, { Topic } from "./hooks/use-topic";
import { useEffect, useState } from "react";
import { extractYouTubeID } from "@/lib/extractYouTubeID";
import { Skeleton } from "@/components/ui/skeleton";
import { ChevronRight } from "lucide-react";
import YoutubePlayer from "@/components/YoutubePlayer";
import {
  sendNavigateToChallengesPageButtonClickEvent,
  sendRecordButtonClickEvent,
} from "./utils/trackingEvent";

const TopicDetail = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const navigate = useNavigate();
  const { fetchTopic } = useTopic();
  const [topic, setTopic] = useState<Topic | null>(null);

  useEffect(() => {
    if (!topicId) return;
    fetchTopic(topicId).then((topic) => {
      setTopic(topic);
    });
  }, [fetchTopic, topicId]);

  const videoId = extractYouTubeID(topic?.video_url || "");

  return (
    <div className="max-w-xl mx-auto">
      <div>
        {videoId ? (
          <div className="h-[75vh] flex justify-center items-center">
            <YoutubePlayer videoId={videoId} />
          </div>
        ) : (
          <Skeleton className="h-[75vh] aspect-[10/16] mx-auto" />
        )}
      </div>
      <div className="mt-4 flex items-center justify-between">
        <div className="flex-1"></div>
        <button
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full w-16 h-16 flex items-center justify-center border-2 border-white"
          onClick={() => {
            sendRecordButtonClickEvent();
            if (!topicId) return;
            navigate(`/upload/${topicId}`);
          }}
        ></button>
        <div className="flex-1 flex justify-end">
          <ChevronRight
            className="h-8 w-8"
            onClick={() => {
              sendNavigateToChallengesPageButtonClickEvent();
              if (!topicId) return;
              navigate(`/Challenges/${topicId}`);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TopicDetail;
