import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type Challenge = {
  challenged_id: string;
  customer_id: string;
  topic_id: string;
  video_provider: string;
  video_url: string;
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
  likes: number;
  comment_counts: number;
};

export type ChallengesResponseType = {
  challenges: Challenge[];
  total: number;
};

export const useChallenges = () => {
  const { fetcher, loading } = useFetch<ResponseType<ChallengesResponseType>>();

  const fetchChallenges = useCallback(
    ({
      filter: { topic_id, customer_id },
    }: {
      filter: {
        topic_id: string;
        customer_id?: string;
      };
    }): Promise<ChallengesResponseType> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `get_challenge_list`,
            options: {
              method: "POST",
              payload: {
                filter: {
                  topic_id,
                  customer_id,
                },
              },
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching challenges error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchChallenges,
    loading,
  };
};

export default useChallenges;
