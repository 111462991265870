import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";
import { useToast } from "@/components/ui/use-toast";

type ResponseType<T> = {
  code: number;
  msg: string;
  data: T;
};

export type Me = {
  customer_id: string;
  name: string;
  user_pic_url: string;
  experience: string;
  styles: string[];
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
  user_type: "teacher" | "student";
};

export const useProfile = () => {
  const { fetcher, loading } = useFetch<ResponseType<Me>>();
  const { toast } = useToast();

  const fetchProfile = useCallback(
    (): Promise<Me> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: "me",
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching profile error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  const updateProfile = useCallback(
    (payload: any): Promise<Me> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: "user",
            options: {
              method: "POST",
              payload,
            },
          },
          onSuccess: (response) => {
            if (!response.data)
              toast({ description: response.msg, variant: "destructive" });
            resolve(response.data);
          },
          onError: (error) => {
            console.error("updating profile error:", error);
            reject(error);
          },
        });
      }),
    [fetcher, toast]
  );

  return {
    fetchProfile,
    updateProfile,
    loading,
  };
};

export default useProfile;
