import tracker from "@/lib/tracker";

const CATEGORY = "signup";

export const sendSignupSuccessEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "signup_success",
    label: "signup_success",
  });
};

export const sendSignupFailedEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "signup_failed",
    label: "signup_failed",
  });
};

export const sendClickLoginButtonEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "login_button_click",
    label: "login_button_click",
  });
};

export const sendSubmitEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "sign_form_submit",
    label: "sign_form_submit",
  });
};

export const onEmailInputFocus = () => {
  tracker.event({
    category: CATEGORY,
    action: "email_input_focus",
    label: "email_input_focus",
  });
};

export const onPasswordInputFocus = () => {
  tracker.event({
    category: CATEGORY,
    action: "password_input_focus",
    label: "password_input_focus",
  });
};

export const onConfirmPasswordInputFocus = () => {
  tracker.event({
    category: CATEGORY,
    action: "confirm_password_input_focus",
    label: "confirm_password_input_focus",
  });
};
