import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type ChallengeLikes = {
  likes: number;
};

export interface Challenge extends ChallengeLikes {
  challenged_id: string;
  customer_id: string;
  topic_id: string;
  video_provider: string;
  video_url: string;
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
  comment_counts: number;
  score: {
    rhythm_precision: number;
    clean_moves: number;
    dress: number;
    confidence: number;
    execution: number;
    overall: number;
  };
}

export const useChallenge = () => {
  const { fetcher, loading } = useFetch<ResponseType<Challenge>>();

  const fetchChallenge = useCallback(
    ({ challenged_id }: { challenged_id: string }): Promise<Challenge> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `challenge/${challenged_id}`,
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching challenge error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  const likeChallenge = useCallback(
    ({ challenged_id }: { challenged_id: string }): Promise<ChallengeLikes> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `challenge/like`,
            options: {
              method: "POST",
              payload: {
                challenged_id,
              },
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("sending challenge like error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchChallenge,
    likeChallenge,
    loading,
  };
};

export default useChallenge;
