import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { YouTubeThumbnail } from "../Topics/components/YouTubeThumbnail";
import useChallenges, { Challenge } from "./hooks/use-challenges";
import { useAuth } from "@/provider/auth";
import { Loader2 } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { DifficultyClassNameMapping } from "../Topics/constants";
import { ThumbsUp, MessageCircle } from "lucide-react";

const MyChallenges = () => {
  // const { topicId } = useParams<{ topicId: string }>();
  const [challenges, setChallenges] = useState<Challenge[] | null>(null);
  const navigate = useNavigate();
  const { fetchChallenges, loading } = useChallenges();
  const { userInfo } = useAuth();

  useEffect(() => {
    if (!userInfo?.customer_id) return;
    fetchChallenges({ filter: { customer_id: userInfo.customer_id } }).then(
      ({ challenges }) => {
        setChallenges(challenges);
      }
    );
  }, [fetchChallenges, userInfo]);

  return (
    <div>
      <h1 className="text-3xl font-bold">My Challenges</h1>
      {loading && <Loader2 className="m-2 mx-auto h-8 w-8 animate-spin" />}
      <div className="">
        {challenges?.map((challenge) => (
          <div className="my-4 flex" key={challenge.challenged_id}>
            <div
              key={challenge.challenged_id}
              className="w-[30%] min-w-[30%]"
              onClick={() =>
                navigate(
                  `/my-challenges/${challenge.topic_id}/${challenge.challenged_id}`
                )
              }
            >
              <YouTubeThumbnail url={challenge.video_url} />
            </div>
            <div className="ml-4 space-y-2">
              <div
                className="text-xl font-extrabold"
                onClick={() =>
                  navigate(
                    `/my-challenges/${challenge.topic_id}/${challenge.challenged_id}`
                  )
                }
              >
                {challenge.topic_name}
              </div>
              <div className="space-x-2">
                <Badge>{challenge.topic_difficulty.toLocaleUpperCase()}</Badge>
                <Badge
                  className={`${DifficultyClassNameMapping[challenge.topic_difficulty]}`}
                >
                  {challenge.topic_style.toLocaleUpperCase()}
                </Badge>
              </div>
              <div className="flex items-center space-x-2">
                <ThumbsUp />

                <span className="text-xl">{challenge.likes}</span>
              </div>
              <div className="flex items-center space-x-2">
                <MessageCircle />
                <span className="text-xl">{challenge.comment_counts}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyChallenges;
