import tracker from "@/lib/tracker";

const CATEGORY = "login";

export const sendLoginSuccessEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "login_success",
    label: "login_success",
  });
};

export const sendLoginFailedEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "login_failed",
    label: "login_failed",
  });
};

export const sendClickSignupButtonEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "signup_button_click",
    label: "signup_button_click",
  });
};

export const sendSubmitEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "login_form_submit",
    label: "login_form_submit",
  });
};

export const onEmailInputFocus = () => {
  tracker.event({
    category: CATEGORY,
    action: "email_input_focus",
    label: "email_input_focus",
  });
};

export const onPasswordInputFocus = () => {
  tracker.event({
    category: CATEGORY,
    action: "password_input_focus",
    label: "password_input_focus",
  });
};
