import { Skeleton } from "@/components/ui/skeleton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { YouTubeThumbnail } from "../Topics/components/YouTubeThumbnail";
import useChallenges, { Challenge } from "./hooks/use-challenges";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from "@/components/ui/dropdown-menu";
import { SortAsc } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  sendChallengeItemClickEvent,
  sendRecordButtonClickEvent,
  sendSortingMenuCloseEvent,
  sendSortingMenuOpenEvent,
} from "./utils/trackingEvent";

type SORTING_TYPES = "created" | "likes" | "comment_counts";

const Challenges = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const [challenges, setChallenges] = useState<Challenge[] | null>(null);
  const navigate = useNavigate();
  const { fetchChallenges, loading } = useChallenges();
  const [currentTypeOfSorting, setCurrentTypeOfSorting] =
    useState<SORTING_TYPES>("created");

  useEffect(() => {
    if (!topicId) return;
    fetchChallenges({ filter: { topic_id: topicId } }).then(
      ({ challenges }) => {
        setChallenges(challenges);
      }
    );
  }, [fetchChallenges, topicId]);

  const isShowSkeleton = loading || !challenges;
  if (isShowSkeleton) {
    return (
      <div>
        <Header />
        <div className="flex flex-wrap justify-around">
          {Array.from({ length: 6 }).map((_, index) => (
            <div key={index} className="w-1/2 p-2">
              <Skeleton className="aspect-[9/16]" />
            </div>
          ))}
        </div>
      </div>
    );
  }

  const sortedChallenges = challenges?.sort((a, b) => {
    if (currentTypeOfSorting === "likes") {
      return b.likes - a.likes;
    }

    if (currentTypeOfSorting === "created") {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    }

    if (currentTypeOfSorting === "comment_counts") {
      return b.comment_counts - a.comment_counts;
    }

    return 0;
  });

  return (
    <div className="max-w-xl mx-auto">
      <div className="relative">
        <Header />
        {sortedChallenges.length > 0 && (
          <div className="absolute top-1 right-0">
            <DropdownMenu
              onOpenChange={(value) => {
                value
                  ? sendSortingMenuOpenEvent()
                  : sendSortingMenuCloseEvent();
              }}
            >
              <DropdownMenuTrigger asChild>
                <Button size="icon" className="h-8 w-8">
                  <SortAsc className="h-3.5 w-3.5" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent className="w-48">
                <DropdownMenuLabel className="text-sm">
                  Sorting by
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuRadioGroup
                  value={currentTypeOfSorting}
                  onValueChange={(value) =>
                    setCurrentTypeOfSorting(value as SORTING_TYPES)
                  }
                >
                  <DropdownMenuRadioItem value="created">
                    Created Date
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="likes">
                    Likes
                  </DropdownMenuRadioItem>
                  <DropdownMenuRadioItem value="comment_counts">
                    Comments Count
                  </DropdownMenuRadioItem>
                </DropdownMenuRadioGroup>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>

      {sortedChallenges.length ? (
        <div className="flex flex-wrap">
          {sortedChallenges.map((challenge) => (
            <div
              key={challenge.challenged_id}
              className="w-1/2 p-2"
              onClick={() => {
                sendChallengeItemClickEvent();
                navigate(
                  `/challenges/${challenge.topic_id}/${challenge.challenged_id}`
                );
              }}
            >
              <YouTubeThumbnail url={challenge.video_url} />
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="mt-3 h-[60vh] flex items-center justify-center border border-gray-400 text-3xl text-center font-bold">
            Waiting for your challenge
          </div>
          <div className="mt-4 flex justify-center">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full w-16 h-16 flex items-center justify-center border-2 border-white"
              onClick={() => {
                sendRecordButtonClickEvent();
                navigate(`/upload/${topicId}`);
              }}
            ></button>
          </div>
        </>
      )}
    </div>
  );
};

const Header = () => (
  <h1 className="text-center text-3xl font-bold">Challenges</h1>
);

export default Challenges;
