import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const isEmail = (value: string) => {
  const MATCH_EMAIL = /^\S+@\S+$/
  return MATCH_EMAIL.test(value)
}