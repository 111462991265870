import tracker from "@/lib/tracker";

const CATEGORY = "topics";

export const onFilterStyleButtonClick = (style: string) => {
  tracker.event({
    category: CATEGORY,
    action: `filter_${style}_topics_button_click`,
    label: `filter_${style}_topics_button_click`,
  });
};
export const onSortMenuOpen = () => {
  tracker.event({
    category: CATEGORY,
    action: "show_sort_menu",
    label: "show_sort_menu",
  });
};
export const onSortMenuClose = () => {
  tracker.event({
    category: CATEGORY,
    action: "close_sort_menu",
    label: "close_sort_menu",
  });
};
export const onSortOptionClick = (option: string) => {
  tracker.event({
    category: CATEGORY,
    action: `sort_by_${option}_button_click`,
    label: `sort_by_${option}_button_click`,
  });
};

export const sendTopicItemClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "topic_item_click",
    label: "topic_item_click",
  });
};

export const sendFeaturedTopicItemClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "featured_topic_item_click",
    label: "featured_topic_item_click",
  });
};
