import ReactGA from "react-ga4";
import { UaEventOptions } from "react-ga4/types/ga4";

const tracker = {
  initialize: () => {
    if (process.env.NODE_ENV !== "production") return;
    ReactGA.initialize("G-66JJE53M4S");
  },
  send: ReactGA.send,
  event: (optionsOrName: UaEventOptions | string, params?: any) => {
    if (process.env.NODE_ENV === "development") console.log(optionsOrName);
    ReactGA.event(optionsOrName, params);
  },
};

export default tracker;
