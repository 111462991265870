import { Button } from "@/components/ui/button";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/provider/auth";

const Landing = () => {
  const navigate = useNavigate();
  const { checkIsLogin } = useAuth();

  const videoClass =
    "max-w-[320px] min-h-[60vh] mx-auto mt-4 border-4 border-black-300 rounded-xl bg-black";
  return (
    <Layout>
      <div className="flex flex-col items-center text-center">
        <div className="w-full">
          <div className="min-h-[80vh] md:min-h-[50vh] bg-slate-50">
            <img
              src="/assets/img/banner.png"
              alt="banner"
              className="w-full object-cover hidden md:block"
            />
            <img
              src="/assets/img/banner-m.png"
              alt="banner"
              className="md:hidden w-full object-cover"
            />
          </div>

          <div className="py-[20px] px-4 md:py-[60px] bg-slate-50">
            <p className="p-2 text-4xl md:text-6xl font-bold text-blue-950">
              Start your challenge in 30secs
            </p>
            <p className="mt-2 md:mt-4 text-xl md:text-2xl text-blue-950">
              Browse topics, create a YouTube Short, and upload it!
            </p>
            <video
              src="/assets/video/start-your-challenge.mp4"
              autoPlay
              playsInline
              muted
              loop
              className={videoClass}
            />
          </div>

          <div className="py-[20px] px-4 md:py-[60px] bg-[#030712]">
            <p className="text-4xl md:text-6xl font-bold">Choose The Topic</p>
            <p className="mt-2 md:mt-4 text-xl md:text-2xl">
              View the topics by style, difficulty, and Challengers
            </p>
            <video
              src="/assets/video/choose-the-topic.mp4"
              autoPlay
              playsInline
              muted
              loop
              className={videoClass}
            />
          </div>

          <div className="py-[20px] px-4 md:py-[60px] bg-slate-50">
            <p className="text-4xl md:text-6xl font-bold text-blue-950">
              Record Your Challenges
            </p>
            <p className="mt-2 md:mt-4 text-xl md:text-2xl text-blue-950">
              Upload your YouTube Short video
            </p>
            <video
              src="/assets/video/recording-your-challenge.mp4"
              autoPlay
              playsInline
              muted
              loop
              className={videoClass}
            />
          </div>

          <div className="py-[20px] px-4 md:py-[60px] bg-[#030712]">
            <p className="text-4xl md:text-6xl font-bold">View Your Status</p>
            <p className="mt-2 md:mt-4 text-xl md:text-2xl">
              View your challenges, statistics, and profile
            </p>
            <video
              src="/assets/video/view-your-status.mp4"
              autoPlay
              playsInline
              muted
              loop
              className={videoClass}
            />
          </div>

          {!checkIsLogin() && (
            <div className="py-[60px] px-4 bg-slate-50">
              <p className="text-6xl font-bold text-blue-950">Try It Now</p>
              <Button
                onClick={() => navigate("/signup")}
                variant="secondary"
                size="lg"
                className="mt-8"
              >
                Sign Up
              </Button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Landing;
