import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useAuth } from "@/provider/auth";
import {
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Textarea } from "@/components/ui/textarea";
import { Comment, useComments } from "@/hooks/useComments";
import useCreateComment from "@/hooks/useCreateComment";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { Loader2 } from "lucide-react";
import { Separator } from "@/components/ui/separator";
import { Badge } from "@/components/ui/badge";

const CommentDrawer = (props: {
  visible: boolean;
  onCLose: Function;
  topicId: string;
  challengeId: string;
  onCreatedComment: Function;
  commentCounts: number;
}) => {
  const {
    topicId,
    challengeId,
    onCreatedComment,
    visible,
    onCLose,
    commentCounts,
  } = props;
  const { userInfo } = useAuth();
  const { createComment, loading: isCreatingComment } = useCreateComment();
  const { fetchComments } = useComments();
  const [comments, setComments] = useState<Comment[]>([]);
  const [comment, setComment] = useState("");
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const fetchCommentsData = useCallback(async () => {
    const payload = {
      filter: { topic_id: topicId, challenged_id: challengeId },
    };
    fetchComments(payload).then((result) => {
      setComments(result.comments);
    });
  }, [fetchComments, topicId, challengeId]);

  const onSubmit = async () => {
    if (!comment) return;
    try {
      await createComment({
        message: comment,
        topicId,
        challengedId: challengeId,
      });
      setComment("");

      await fetchCommentsData();

      toast({
        description: "Comment submitted",
        duration: 1500,
      });

      onCreatedComment();
    } catch (error) {
      console.error(error);
      toast({
        description: "Failed to submit comment",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (!visible || commentCounts === 0) return;
    fetchCommentsData();
    setTimeout(() => {
      textAreaRef.current?.focus();
    });
  }, [visible, fetchCommentsData, commentCounts]);

  return (
    <Drawer open={visible} onClose={() => onCLose()}>
      <DrawerContent aria-describedby={undefined}>
        <DrawerHeader>
          <DrawerTitle>Comment</DrawerTitle>
        </DrawerHeader>
        <div className="overflow-y-auto h-[60vh] p-4">
          <div className="flex items-center">
            <Avatar>
              <AvatarImage src={userInfo?.user_pic_url} />
              <AvatarFallback className="bg-slate-300 text-black">
                {userInfo?.name[0]?.toUpperCase()}
              </AvatarFallback>
            </Avatar>
            <Textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              className="ml-3"
              placeholder="Enter your comment"
              ref={textAreaRef}
            />
          </div>
          <Button
            className="w-full mt-3"
            onClick={onSubmit}
            disabled={isCreatingComment}
          >
            {isCreatingComment && (
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            )}
            Submit
          </Button>
          <Separator className="my-4" />
          <Comments comments={comments} />
        </div>
      </DrawerContent>
    </Drawer>
  );
};
const Comments = ({ comments }: { comments: Comment[] }) => {
  return (
    <div className="flex flex-col gap-2">
      {comments.map((comment) => (
        <div key={comment.comment_id} className="flex">
          <Avatar className="mt-1">
            {/* TODO: add user avatar  */}
            <AvatarFallback className="bg-slate-300 text-black">
              {comment.customer_name[0]}
            </AvatarFallback>
          </Avatar>
          <div className="ml-3">
            <div className="flex items-center">
              <div>{`@${comment.customer_name}`}</div>
              {comment.is_teacher && (
                <>
                  <span className="mx-1">-</span>
                  <Badge className="text-xs h-4 px-2">Teacher</Badge>
                </>
              )}
            </div>
            <div>{comment.message}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentDrawer;
