import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useSignup from "./hooks/useSignup";
import useLogin from "./hooks/useLogin";
import useProfile, { Me } from "@/pages/Profile/hooks/use-profile";
import token from "@/lib/utils/token";

type AuthProviderProps = {
  children: React.ReactNode;
};

type UserInfo = Me;

type AuthProviderState = {
  userInfo: UserInfo;
  signup: (email: string, password: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  checkIsLogin: () => boolean;
  initUserInfo: () => void;
  isLogged: boolean | null;
  useInfoInitiated: boolean;
};

const initialState: AuthProviderState = {
  userInfo: {
    name: "",
    customer_id: "",
    experience: "",
    styles: [],
    user_pic_url: "",
    last_updated: 0,
    last_updated_by: "",
    created: 0,
    created_by: "",
    user_type: "student",
  },
  signup: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => null,
  checkIsLogin: () => false,
  initUserInfo: () => null,
  isLogged: null,
  useInfoInitiated: false,
};

const AuthContext = createContext<AuthProviderState>(initialState);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [userInfo, setUserInfo] = useState<UserInfo>(initialState.userInfo);
  const [isLogged, setIsLogged] = useState<boolean>(false);
  const { fetchSignup } = useSignup();
  const { fetchLogin } = useLogin();
  const { fetchProfile, updateProfile } = useProfile();
  const [useInfoInitiated, setUserInfoInitiated] = useState<boolean>(false);

  const signup = async (email: string, password: string) => {
    const response = await fetchSignup({ email, password });
    token.set({
      authToken: response.auth_token,
      refreshToken: response.refresh_token,
    });

    await updateProfile({
      name: email.split("@")[0],
      styles: ["hiphop"],
      experience: "beginner",
    });

    setIsLogged(true);
  };

  const login = async (email: string, password: string) => {
    const response = await fetchLogin({ email, password });
    token.set({
      authToken: response.auth_token,
      refreshToken: response.refresh_token,
    });
    initUserInfo();
    setIsLogged(true);
  };

  const logout = () => {
    token.clear();
    localStorage.removeItem("ls_user_type");
    setIsLogged(false);
    window.location.href = "/";
  };

  // TODO: workaround, need to check token is expired or not
  const checkIsLogin = () => {
    const { authToken, refreshToken } = token.get();
    return !!authToken && !!refreshToken;
  };

  const initUserInfo = useCallback(async () => {
    setUserInfoInitiated(false);
    const profile = await fetchProfile();
    setUserInfo(profile);
    setUserInfoInitiated(true);
    localStorage.setItem("ls_user_type", profile.user_type);
  }, [fetchProfile]);

  useEffect(() => {
    setIsLogged(checkIsLogin());
    setUserInfoInitiated(true);
  }, []);

  const value = {
    userInfo,
    signup,
    login,
    logout,
    checkIsLogin,
    initUserInfo,
    isLogged,
    useInfoInitiated,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
