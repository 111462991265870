import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { useScrollToTop } from "@/hooks/useScrollToTop";

const Layout = ({ children }: any) => {
  useScrollToTop();

  return (
    <div className="mx-auto">
      <Header />
      <div className="mx-auto">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
