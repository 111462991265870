import tracker from "@/lib/tracker";

const CATEGORY = "topic_detail";

export const sendRecordButtonClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: `record_button_click`,
    label: `record_button_click`,
  });
};
export const sendChallengeItemClickEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "challenge_item_click",
    label: "challenge_item_click",
  });
};

export const sendSortingMenuOpenEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "show_sort_menu",
    label: "show_sort_menu",
  });
};

export const sendSortingMenuCloseEvent = () => {
  tracker.event({
    category: CATEGORY,
    action: "close_sort_menu",
    label: "close_sort_menu",
  });
};
