import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

type Settings = {
  featured: boolean;
};

export type Topic = {
  customer_id: string;
  name: string;
  topic_id: string;
  video_provider: string;
  video_url: string;
  difficulty: string;
  settings: Settings;
  challenged_counts: number;
  style: string;
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
};

export const useTopic = () => {
  const { fetcher, loading } = useFetch<ResponseType<Topic>>();

  const fetchTopic = useCallback(
    (topic_id: string): Promise<Topic> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `topic/${topic_id}`,
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching topic detail error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchTopic,
    loading,
  };
};

export default useTopic;
