import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { FlameIcon } from "lucide-react";
import { useAuth } from "@/provider/auth/";
import useStatistics from "@/pages/Statistics/hooks/useStatistics";
import { cn } from "@/lib/utils/common";

const Header = ({ fixed = false }: { fixed?: boolean }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogged, logout, userInfo, initUserInfo, useInfoInitiated } =
    useAuth();
  const [continuedChallenges, setContinuedChallenges] = useState(0);
  const { fetchStatistics } = useStatistics();

  const isShowSignup =
    useInfoInitiated &&
    !isLogged &&
    !["/signup", "/login"].includes(location.pathname);

  const isTeacher = userInfo.user_type === "teacher";

  useEffect(() => {
    if (!isLogged) return;
    initUserInfo();
    fetchStatistics()
      .then((data) => {
        setContinuedChallenges(data.continued_challenges);
      })
      .catch(console.error);
  }, [isLogged, initUserInfo, fetchStatistics]);

  const headerCss = cn(
    `${fixed ? "sticky top-0" : ""} min-h-[56px] px-4 pt-2 pb-2 flex justify-between items-center bg-[#030712] z-50`
  );

  return (
    <div className={headerCss}>
      <div className="hover:cursor-pointer" onClick={() => navigate("/")}>
        <img src="/assets/img/logo.png" alt="logo" loading="lazy" width="140" />
      </div>
      {continuedChallenges > 0 && (
        <div
          className="ml-auto flex text-yellow-500 mr-3 hover:cursor-pointer"
          onClick={() => navigate("/statistics")}
        >
          <FlameIcon className="h-6 w-6 mr-1" />
          <span className="text-xl">{continuedChallenges}</span>
        </div>
      )}
      {isLogged && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Avatar className="hover:cursor-pointer">
              {/* <AvatarImage src={userInfo?.user_pic_url} /> */}
              <AvatarFallback className="bg-slate-300 text-black">
                {userInfo?.name[0]?.toUpperCase()}
              </AvatarFallback>
            </Avatar>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56">
            {/* teacher only */}
            {isTeacher && (
              <DropdownMenuItem onClick={() => navigate("/my-topics")}>
                My Topics
              </DropdownMenuItem>
            )}
            <DropdownMenuItem onClick={() => navigate("/my-challenges")}>
              My Challenges
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate("/statistics")}>
              Statistics
            </DropdownMenuItem>
            <DropdownMenuItem onClick={() => navigate("/profile")}>
              Profile
            </DropdownMenuItem>
            <DropdownMenuItem onClick={logout}>Logout</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
      {isShowSignup && (
        <Button onClick={() => navigate("/signup")}>Sign Up</Button>
      )}
    </div>
  );
};

export default Header;
