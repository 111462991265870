import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type StyleKeys = "locking" | "hiphop" | "breaking";

type StyleChallenged = {
  [key in StyleKeys]: {
    challenged: number;
    topics: number;
  };
};

export type Statistics = {
  style_challenged: StyleChallenged;
  continued_challenges: number;
  global_challenges_ranking: number;
  challenged_scoring: {
    avg: number;
    highest: number;
  };
  last_updated: number;
  last_updated_by: string;
};

export const useStatistics = () => {
  const { fetcher, loading } = useFetch<ResponseType<Statistics>>();

  const fetchStatistics = useCallback(
    (): Promise<Statistics> =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: "statistics",
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching statistics error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchStatistics,
    loading,
  };
};

export default useStatistics;
