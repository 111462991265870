import { ThumbsUp, MessageCircle } from "lucide-react";
import { cn } from "@/lib/utils/common";

const VideoAction = ({
  likes = 0,
  commentCounts = 0,
  onLikeClicked,
  onCommentClicked,
  children,
}: {
  likes?: number;
  commentCounts?: number;
  onLikeClicked: Function;
  onCommentClicked: Function;
  children: React.ReactNode;
}) => {
  return (
    <div className="relative w-full h-full max-w-lg max-h-md border-0">
      {children}
      <div className="absolute right-1 bottom-20 opacity-60">
        <div>
          <IConWrapper onClick={onLikeClicked}>
            <ThumbsUp className="h-6 w-6" />
          </IConWrapper>
          {Boolean(likes) && <div className="text-center text-sm">{likes}</div>}
        </div>

        <div className="mt-4">
          <IConWrapper onClick={onCommentClicked}>
            <MessageCircle className="h-6 w-6" />
          </IConWrapper>
          {Boolean(commentCounts) && (
            <div className="text-center text-sm">{commentCounts}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const IConWrapper = ({
  children,
  className,
  onClick,
}: {
  children: React.ReactNode;
  className?: string;
  onClick: Function;
}) => {
  const wrapperClass = cn(
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 border border-input bg-background hover:bg-accent hover:text-accent-foreground h-10 w-10",
    className
  );
  return (
    <div className={wrapperClass} onClick={() => onClick()}>
      {children}
    </div>
  );
};

export default VideoAction;
