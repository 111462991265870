import { extractYouTubeID } from "@/lib/extractYouTubeID";

const qualityMap = {
  default: "0",
  high: "maxresdefault",
};

export const YouTubeThumbnail = (props: {
  url: string;
  quality?: "default" | "high";
}) => {
  const videoId = extractYouTubeID(props.url);
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/${qualityMap[props.quality || "default"]}.jpg`;

  return (
    <div className="relative overflow-hidden w-full aspect-[9/16] rounded-md">
      <img
        src={thumbnailUrl}
        alt="YouTube Thumbnail"
        className="absolute top-0 left-0 w-full h-full object-cover transition-all hover:scale-105"
      />
    </div>
  );
};
