import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="my-8 w-full p-4 text-center">
      <div className="flex justify-around">
        <Link to="/landing">About Us</Link>
        <Link to="https://forms.gle/m7MEq2n59NSU2hUz9" target="_blank">
          Contact Us
        </Link>
        <Link to="/terms-of-service">Terms</Link>
      </div>
      <div className="mt-2">© 2024 LeetStep</div>
    </footer>
  );
};

export default Footer;
