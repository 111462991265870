const Terms = () => {
  const className = {
    p: "my-5 text-xl",
  };
  return (
    <div className="pt-[80px]">
      <h1 className="text-4xl font-extrabold">Terms of Use</h1>
      <p className={className.p}>
        <strong>Effective Date: August 24, 2024</strong>
      </p>
      <p className={className.p}>
        Welcome to{" "}
        <a href="https://LeetStep.com" className="font-bold">
          https://LeetStep.com
        </a>{" "}
        ! By accessing or using our website/app/services (collectively referred
        to as "Services"), you agree to comply with and be bound by the
        following terms and conditions (the "Terms of Use"). Please read them
        carefully. If you do not agree to these Terms of Use, please do not use
        our Services.
      </p>
      <p className={className.p}>
        <strong>1. Acceptance of Terms</strong>
        <br />
        By using our Services, you affirm that you are at least 18 years old, or
        if you are under 18, you have obtained parental or legal guardian
        consent to use our Services. Your use of the Services is subject to
        these Terms of Use, our Privacy Policy, and any other policies or
        guidelines we may provide.
      </p>
      <p className={className.p}>
        <strong>2. Modifications to Terms</strong>
        <br />
        We reserve the right to change, modify, or revise these Terms of Use at
        any time. We will notify you of any changes by updating the "Effective
        Date" at the top of this document. Your continued use of our Services
        following any such changes constitutes your acceptance of the revised
        Terms of Use.
      </p>
      <p className={className.p}>
        <strong>3. Use of the Services</strong>
        <br />
        <ul className="pl-5 list-disc list-outside [&_ul]:list-[revert]">
          <li>
            <b>Permitted Use</b>: You are granted a limited, non-exclusive,
            non-transferable, and revocable license to use the Services for
            personal, non-commercial use only.
          </li>
          <li>
            <b>Prohibited Use:</b> You agree not to use the Services for any
            unlawful purpose or in any way that could harm us, our affiliates,
            or any other party. This includes, but is not limited to:
            <ul className="pl-5 list-disc list-outside">
              <li>Hacking, interfering with, or disrupting the Services.</li>
              <li>
                Using automated systems to collect data or content from the
                Services.
              </li>
              <li>Violating any applicable laws or regulations.</li>
              <li>Infringing on the intellectual property rights of others.</li>
            </ul>
          </li>
        </ul>
      </p>

      <p className={className.p}>
        <strong>4. User Content</strong>
        <br />
        <ul className="pl-5 list-disc list-outside">
          <li>
            Responsibility: You are solely responsible for any content you
            upload, post, or otherwise make available through the Services
            ("User Content").
          </li>
          <li>
            License: By submitting User Content, you grant us a worldwide,
            royalty-free, perpetual, irrevocable, and sublicensable license to
            use, reproduce, modify, publish, and distribute such content in
            connection with the Services.
          </li>
        </ul>
      </p>

      <p className={className.p}>
        <strong>5. Intellectual Property</strong>
        <br />
        All content, trademarks, logos, and intellectual property related to the
        Services are owned by{" "}
        <a href="https://LeetStep.com" className="font-bold">
          https://LeetStep.com
        </a>{" "}
        or our licensors. You may not use any of these materials without our
        express written consent.
      </p>

      <p className={className.p}>
        <strong>6. Termination</strong>
        <br />
        We reserve the right to suspend or terminate your access to the Services
        at any time, for any reason, including violation of these Terms of Use.
      </p>

      <p className={className.p}>
        <strong>7. Disclaimers</strong>
        <br />
        The Services are provided "as is" without warranties of any kind, either
        express or implied. We do not guarantee that the Services will be
        available, secure, or error-free at all times.
      </p>

      <p className={className.p}>
        <strong>8. Limitation of Liability</strong>
        <br />
        To the fullest extent permitted by law,{" "}
        <a href="https://LeetStep.com" className="font-bold">
          https://LeetStep.com
        </a>{" "}
        shall not be liable for any indirect, incidental, special, or
        consequential damages arising out of or in connection with the use of
        the Services.
      </p>

      <p className={className.p}>
        <strong>9. Contact Information</strong>
        <br />
        If you have any questions or concerns about these Terms of Use, please
        contact us by{" "}
        <a
          href="https://forms.gle/sJytwx86dPygQb7v9"
          target="_blank"
          className="font-bold break-words"
          rel="noreferrer noopener"
        >
          https://forms.gle/sJytwx86dPygQb7v9
        </a>
        .
      </p>
    </div>
  );
};

export default Terms;
