export type GenerateQueryStringProps = { [key: string]: string | string[] };

const generateQueryString = (payload: GenerateQueryStringProps): string => {
  const params = new URLSearchParams();

  Object.entries(payload).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(`${key}[]`, `${value}`));
    } else {
      params.append(key, `${value}`);
    }
  });

  return String(params);
};

export default generateQueryString;
