import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type Comment = {
  comment_id: string;
  challenged_id: string;
  customer_id: string;
  customer_name: string;
  topic_id: string;
  message: string;
  last_updated: number;
  last_updated_by: string;
  created: number;
  created_by: string;
  is_teacher: boolean;
};

export type CommentsResponseType = {
  comments: Comment[];
  total: number;
};

export type Payload = {
  search: string;
  filter: {
    topic_id: string;
    challenged_id: string;
  };
  paging: {
    offset: number;
    limit: number;
  };
  order: {
    column: string;
    direction: string;
  };
};

export const useComments = () => {
  const { fetcher, loading } = useFetch<ResponseType<CommentsResponseType>>();

  const fetchComments = useCallback<
    (payload: Payload | unknown) => Promise<CommentsResponseType>
  >(
    (payload) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: `get_comment_list`,
            options: {
              method: "POST",
              payload: payload || null,
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("fetching comments error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    fetchComments,
    loading,
  };
};

export default useComments;
