import { Progress } from "@/components/ui/progress";
import { useEffect, useState } from "react";
import useStatistics, {
  Statistics as StatisticsType,
  StyleKeys,
} from "./hooks/useStatistics";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { FlameIcon } from "lucide-react";

const STYLES: StyleKeys[] = ["locking", "breaking", "hiphop"];
const Statistics = () => {
  const { fetchStatistics } = useStatistics();
  const [statistics, setStatistics] = useState<StatisticsType>({
    style_challenged: {
      locking: {
        challenged: 0,
        topics: 0,
      },
      hiphop: {
        challenged: 0,
        topics: 0,
      },
      breaking: {
        challenged: 0,
        topics: 0,
      },
    },
    continued_challenges: 0,
    global_challenges_ranking: 0,
    challenged_scoring: {
      avg: 0,
      highest: 0,
    },
    last_updated: 0,
    last_updated_by: "",
  });

  useEffect(() => {
    fetchStatistics()
      .then((data) => {
        setStatistics(data);
      })
      .catch(console.error);
  }, [fetchStatistics]);

  const styleChallengedList = STYLES.map((style) => {
    if (!statistics) return {};
    const styleChallenged = statistics.style_challenged[style];
    return {
      name: style,
      challenged: styleChallenged?.challenged,
      topics: styleChallenged?.topics,
      progress: (styleChallenged?.challenged / styleChallenged?.topics) * 100,
    };
  });

  return (
    <div className="">
      <Card className="border-2 mt-2">
        <CardHeader className="text-center text-2xl font-bold">
          Continued Challenges
        </CardHeader>
        <CardContent className="border-t-2 p-4">
          <div className="text-2xl font-bold text-center flex justify-center items-center text-yellow-500">
            <FlameIcon className="h-6 w-6 mr-1 inline-block" />
            {statistics?.continued_challenges}
          </div>
        </CardContent>
      </Card>

      <Card className="border-2 mt-6">
        <CardHeader className="text-center text-2xl font-bold">
          Style Challenged
        </CardHeader>
        <CardContent className="border-t-2 p-4 pt-0">
          {styleChallengedList.map((styleChallenged) => (
            <div className="mt-4" key={styleChallenged.name}>
              <div className="flex justify-between items-center">
                <div className="text-xl font-bold">
                  {styleChallenged.name?.toLocaleUpperCase()}
                </div>
                <div className="text-sm font-bold">
                  {styleChallenged.challenged} / {styleChallenged.topics}
                </div>
              </div>
              <Progress className="mt-1" value={styleChallenged.progress} />
            </div>
          ))}
        </CardContent>
      </Card>

      <Card className="border-2 mt-6">
        <CardHeader className="text-center text-2xl font-bold">
          Global Challenges Ranking
        </CardHeader>
        <CardContent className="border-t-2 p-4">
          <div className="text-2xl font-bold text-center">
            {statistics?.global_challenges_ranking}
          </div>
        </CardContent>
      </Card>

      <Card className="border-2 my-6">
        <CardHeader className="text-center text-2xl font-bold">
          Challenged Scoring
        </CardHeader>
        <CardContent className="flex flex-row border-t-2 p-4">
          <div className="grid flex-1 auto-rows-min gap-0.5 text-center">
            <div className="text-muted-foreground">avg</div>
            <div className="text-2xl font-bold leading-none">
              {statistics?.challenged_scoring.avg.toFixed(2)}
            </div>
          </div>
          <Separator orientation="vertical" className="mx-2 h-14 w-[2px]" />
          <div className="grid flex-1 auto-rows-min gap-0.5 text-center">
            <div className="text-muted-foreground">highest</div>
            <div className="text-2xl font-bold leading-none">
              {statistics?.challenged_scoring.highest}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Statistics;
