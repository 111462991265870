import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import Upload from "@/pages/Upload";
import Topics from "@/pages/Topics";
import TopicDetail from "@/pages/TopicDetail";
import Challenges from "@/pages/Challenges";
import ChallengeDetail from "@/pages/ChallengeDetail";
import { useAuth } from "@/provider/auth";
import { Signup } from "@/pages/Signup";
import { Login } from "@/pages/Login";
import { Layout } from "./Layout";
import Profile from "@/pages/Profile";
import MyChallenges from "@/pages/MyChallenges";
import MyChallengeDetail from "@/pages/MyChallengeDetail";
import Statistics from "@/pages/Statistics";
import MyTopics from "@/pages/MyTopics/MyTopics";
import Landing from "@/pages/Landing/Landing";
import Terms from "@/pages/Terms/Terms";

const ProtectedRoute = ({
  children,
  auth,
}: {
  children: React.ReactNode;
  auth?: "teacher" | "student";
}) => {
  const { checkIsLogin } = useAuth();

  const currentPath = window.location.pathname;
  const isTeacher = localStorage.getItem("ls_user_type") === "teacher";
  if (auth === "teacher" && !isTeacher) return <Navigate to="/" replace />;

  if (!checkIsLogin()) {
    return (
      <Navigate
        to={`/signup?redirect=${encodeURIComponent(currentPath)}`}
        replace
      />
    );
  }
  return <>{children}</>;
};

const router = createBrowserRouter([
  {
    path: "/landing",
    element: <Landing />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Topics />,
      },
      {
        path: "/terms-of-service",
        element: <Terms />,
      },
      {
        path: "/topics/:topicId",
        element: <TopicDetail />,
      },
      {
        path: "/upload/:topicId",
        element: (
          <ProtectedRoute>
            <Upload />
          </ProtectedRoute>
        ),
      },
      {
        path: "/challenges/:topicId",
        element: (
          <ProtectedRoute>
            <Challenges />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-challenges",
        element: (
          <ProtectedRoute>
            <MyChallenges />
          </ProtectedRoute>
        ),
      },
      {
        path: "/my-challenges/:topicId/:challengeId",
        element: (
          <ProtectedRoute>
            <MyChallengeDetail />
          </ProtectedRoute>
        ),
      },
      {
        path: "/challenges/:topicId/:challengeId",
        element: <ChallengeDetail />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/statistics",
        element: <Statistics />,
      },
      {
        path: "/my-topics",
        element: (
          <ProtectedRoute auth="teacher">
            <MyTopics />
          </ProtectedRoute>
        ),
      },
      {
        path: "*",
        element: <h1 className="text-3xl font-bold">404 Not Found</h1>,
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
