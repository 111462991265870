import { Images } from "lucide-react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import useCreateChallenge, { PrivacyType } from "./hooks/use-create-challenge";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/provider/auth";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";

const Upload = () => {
  const { topicId } = useParams<{ topicId: string }>();
  const navigate = useNavigate();
  const [challengeUrl, setChallengeUrl] = useState("");
  const { createChallenge } = useCreateChallenge();
  const { toast } = useToast();
  const { userInfo } = useAuth();
  const [privacy, setPrivacy] = useState<PrivacyType>("private");

  const onChallengeSend = () => {
    if (!topicId || !challengeUrl) return;
    (async () => {
      try {
        const { challenged_id } = await createChallenge({
          topicId,
          videoUrl: challengeUrl,
          customerId: userInfo.customer_id,
          privacy,
        });
        toast({
          description: "Challenge sent successfully",
          duration: 1500,
        });
        navigate(`/challenges/${topicId}/${challenged_id}`);
      } catch (error) {
        console.error(error);
        toast({
          description: "Failed to send challenge",
          variant: "destructive",
        });
      }
    })();
  };

  return (
    <div className="flex flex-col w-full items-center">
      <h1 className="text-3xl font-bold">Upload Page</h1>
      <Images className="mt-10" size={100} />
      <div className="flex flex-col gap-y-6 w-full max-w-sm items-center space-x-2 mt-10">
        <form className="w-full">
          <div className="grid w-full items-center gap-4">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="">Youtube Shorts URL</Label>
              <Input
                type="url"
                value={challengeUrl}
                placeholder="https://youtube.com/shorts/<videoId>?feature=share"
                onChange={(event) => {
                  setChallengeUrl(event.target.value);
                }}
              />
            </div>
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="">Privacy</Label>
              <Select
                value={privacy}
                onValueChange={(value) => setPrivacy(value as PrivacyType)}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Select Experience" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="public">Public</SelectItem>
                  <SelectItem value="private">Private</SelectItem>
                  <SelectItem value="teacher-only">Teacher only</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
        </form>

        <Button onClick={() => onChallengeSend()}>Send Challenge</Button>
      </div>
    </div>
  );
};

export default Upload;
