import { useEffect } from "react";
import useProfile from "./hooks/use-profile";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useToast } from "@/components/ui/use-toast";
import { useState } from "react";
import { Loader2 } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Tag, TagInput } from "emblor";

const Profile = () => {
  const [name, setName] = useState<string>("");
  const [experience, setExperience] = useState<string>("beginner");
  const { fetchProfile, updateProfile, loading } = useProfile();
  const { toast } = useToast();
  const [tags, setTags] = useState<Tag[]>([]);
  const [activeTagIndex, setActiveTagIndex] = useState<number | null>(null);

  useEffect(() => {
    fetchProfile()
      .then((profile) => {
        if (!profile) return;
        setName(profile.name);
        setTags(profile.styles.map((style) => ({ id: style, text: style })));
        setExperience(profile.experience);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [fetchProfile]);

  const onUpdate = () => {
    if (!name) {
      return toast({
        description: "Name is required",
        variant: "destructive",
      });
    }

    if (!tags.length) {
      return toast({
        description: "At least one style is required",
        variant: "destructive",
      });
    }

    const styles = tags.map((tag) => tag.text);
    updateProfile({ name, styles, experience }).then((profile) => {
      toast({ description: "Profile updated" });
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle className="text-center">Profile</CardTitle>
        </CardHeader>

        <CardContent>
          <form>
            <div className="grid w-full items-center gap-4">
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="name">Dancer Name</Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="style">Styles</Label>
                <TagInput
                  placeholder="Enter a style"
                  className="gap-2 pl-2"
                  tags={tags}
                  setTags={(newTags) => {
                    setTags(newTags);
                  }}
                  activeTagIndex={activeTagIndex}
                  setActiveTagIndex={setActiveTagIndex}
                />
              </div>

              {/* selector */}
              <div className="flex flex-col space-y-1.5">
                <Label htmlFor="experience">Experience</Label>
                <Select
                  value={experience}
                  onValueChange={(value) => setExperience(value)}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select Experience" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="beginner">Beginner</SelectItem>
                    <SelectItem value="medium">Medium</SelectItem>
                    <SelectItem value="expert">Expert</SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
          </form>
        </CardContent>

        <CardFooter className="flex flex-col justify-center">
          <Button className="w-full" onClick={onUpdate} disabled={loading}>
            {loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            Update
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default Profile;
