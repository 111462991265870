"use client";

import {
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from "recharts";

import { Card, CardContent } from "@/components/ui/card";
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";

const chartConfig = {
  desktop: {
    label: "Score",
    theme: {
      light: "blue",
      dark: "#2563eb",
    },
  },
} satisfies ChartConfig;

type Props = {
  rhythmPrecision: number;
  cleanMoves: number;
  dress: number;
  confidence: number;
  execution: number;
  overall: number;
};

export function Component({
  rhythmPrecision,
  cleanMoves,
  dress,
  confidence,
  execution,
  overall,
}: Props) {
  const chartData = [
    { type: "Rhythm Precision", desktop: rhythmPrecision },
    { type: "Clean Moves", desktop: cleanMoves },
    { type: "Dress", desktop: dress },
    { type: "Confidence", desktop: confidence },
    { type: "Execution", desktop: execution },
    { type: "Overall", desktop: overall },
  ];
  return (
    <Card className="border-0">
      <CardContent className="p-0">
        <ChartContainer config={chartConfig} className="">
          <RadarChart data={chartData} className="text">
            <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
            <PolarAngleAxis dataKey="type" fontSize={14} />
            <PolarGrid />
            <Radar
              dataKey="desktop"
              fill="var(--color-desktop)"
              fillOpacity={0.4}
            />
            <PolarRadiusAxis
              angle={60}
              stroke="hsla(var(--foreground))"
              orientation="middle"
              axisLine={false}
            />
          </RadarChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
