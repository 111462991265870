import generateQueryString from './utils/generate-query-string';
import type { GenerateQueryStringProps } from './utils/generate-query-string';
import type { FetcherBaseUrl, FetcherPath, FetcherOptions, Fetcher, FetcherPromise } from './types';
export type { FetcherOptions, Fetcher } from './types';

export class FetchError extends Error {
  constructor(public status: Response['status'], message?: string) {
    super(message);
  }
}

const HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const fetcher = async (
  baseUrl: FetcherBaseUrl,
  path: FetcherPath,
  { method = 'GET', headers = {}, payload }: FetcherOptions = {}
): FetcherPromise => {
  const urlInstance = new URL(`${baseUrl}${path}`);

  const isGETorHEADMethod = ['GET', 'HEAD'].includes(method);
  if (isGETorHEADMethod && payload) urlInstance.search = generateQueryString(payload as GenerateQueryStringProps);

  const response = await fetch(String(urlInstance), {
    method,
    headers: {
      ...HEADERS,
      ...headers,
    },
    body: !isGETorHEADMethod && payload ? JSON.stringify(payload) : undefined,
  });

  if (response.status === 204) return;

  try {
    const result = await response.json();

    if (response.ok) {
      return result;
    } else {
      if (response.status === 401) return { status: 401, message: 'Unauthorized', ...result };
      throw new FetchError(response.status, result.msg);
    }
  } catch (error) {
    if (response.ok) return;
    throw error;
  }
};

export const initFetcher = (baseUrl: FetcherBaseUrl): Fetcher => {
  return (path, options = {}) => fetcher(baseUrl, path, options);
};
