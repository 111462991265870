import type { UseFetchPayload, Return } from "./types";
import { useState, useCallback } from "react";
import { FetchError, fetcher as utilsFetcher } from "@/lib/fetcher";
import token from "@/lib/utils/token";

function useFetch<T>(): Return<T> {
  const [loading, setLoading] = useState(false);

  const fetcher = useCallback(
    async ({ fetchArgs, onSuccess, onError }: UseFetchPayload<T>) => {
      await (async () => {
        try {
          setLoading(true);
          const basePath = getBasePath();
          const { path, options } = fetchArgs;
          const headers = await getApiHeaders(path);
          let result = await utilsFetcher(basePath, path, {
            headers,
            ...options,
          });

          if (result.status === 401) {
            const refreshToken = localStorage.getItem("dl-refresh-token");
            const isInLoginPage = window.location.pathname === "/login";
            if (!refreshToken && !isInLoginPage)
              window.location.href = "/login";
            const tokenResult = await utilsFetcher(basePath, "refresh_token", {
              method: "POST",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("dl-refresh-token")}`,
              },
            });

            if (tokenResult.status === 401 && !isInLoginPage) {
              token.clear();
              window.location.href = "/login";
            }

            token.set({
              authToken: tokenResult.data.auth_token,
              refreshToken: tokenResult.data.refresh_token,
            });
            // TODO: check if the refresh token is expired

            // retry with new token
            const headers = await getApiHeaders(path);
            result = await utilsFetcher(basePath, path, {
              headers,
              ...options,
            });
          }

          onSuccess?.(result as T);
        } catch (error) {
          onError?.(error as FetchError);
        } finally {
          setLoading(false);
        }
      })();
    },
    []
  );

  return {
    loading,
    fetcher,
  };
}

const getBasePath = () => {
  return process.env.REACT_APP_API_URL || "";
};

const getApiHeaders = async (path: string) => {
  if (path.includes("topic")) return { Authorization: "" };

  const token = localStorage.getItem("dl-auth-token");

  return {
    Authorization: `Bearer ${token}`,
  };
};

export default useFetch;
