import { useCallback } from "react";
import useFetch from "@/hooks/use-fetch";

type ResponseType<T> = {
  code: string;
  msg: string;
  data: T;
};

export type CreateCommentResponseType = {
  comment_id: string;
};

export const useCreateComment = () => {
  const { fetcher, loading } =
    useFetch<ResponseType<CreateCommentResponseType>>();

  const createComment = useCallback<
    (params: {
      challengedId: string;
      topicId: string;
      message: string;
    }) => Promise<CreateCommentResponseType>
  >(
    ({ challengedId, topicId, message }) =>
      new Promise((resolve, reject) => {
        fetcher({
          fetchArgs: {
            path: "comment",
            options: {
              method: "POST",
              payload: {
                challenged_id: challengedId,
                topic_id: topicId,
                message,
              },
            },
          },
          onSuccess: (response) => {
            resolve(response.data);
          },
          onError: (error) => {
            console.error("create comment error:", error);
            reject(error);
          },
        });
      }),
    [fetcher]
  );

  return {
    createComment,
    loading,
  };
};

export default useCreateComment;
