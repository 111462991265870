const TOKEN_TYPE = {
  AUTH: "dl-auth-token",
  REFRESH: "dl-refresh-token",
};

const get = () => {
  return {
    authToken: localStorage.getItem(TOKEN_TYPE.AUTH),
    refreshToken: localStorage.getItem(TOKEN_TYPE.REFRESH),
  };
};

const set = ({
  authToken,
  refreshToken,
}: {
  authToken: string;
  refreshToken: string;
}) => {
  localStorage.setItem(TOKEN_TYPE.AUTH, authToken || "");
  localStorage.setItem(TOKEN_TYPE.REFRESH, refreshToken || "");
};

const clear = () => {
  localStorage.removeItem(TOKEN_TYPE.AUTH);
  localStorage.removeItem(TOKEN_TYPE.REFRESH);
};

const token = {
  get,
  set,
  clear,
};

export default token;
